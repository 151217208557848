<template>
    <!-- 走进城银 -->
    <div class="Enter">
        <div class="header">
            <Head :add="routeAdd"></Head>
        </div>
        <div class="main">
            <div class="logo">
                <img src="../assets/images/enter_logo.png" alt />
            </div>
            <template v-for="(item,index) in renderList">
                <div :key="index" :class="item.className">
                    <div class="flip">
                        <div class="front">
                            <div :class="item.imgClass"></div>
                            <div class="name">{{item.name}}</div>
                            <div class="bar"></div>
                        </div>
                        <div class="back">{{item.txt}}</div>
                    </div>
                </div>
            </template>
            <div class="text">
                <div>重庆城银科技股份有限公司作为专业智慧信息技术咨询运维服务引领者，始终以用科技解决小问题，改变大世界为理念，</div>
                <div>不断开展前沿科技探索、高端技术研究应用和核心产品的自主研发,致力于让智慧科技链接世界。</div>
            </div>
        </div>
    </div>
</template>

<script>
import Head from "../components/Head.vue";
export default {
    components: {
        Head,
    },
    data() {
        return {
            routeAdd: "",
            renderList: [
                {
                    className: "item hex_1",
                    imgClass: "img_1",
                    name: "城银股份",
                    txt: "以智慧医疗为核心，提供相关核心自主产品和解决方案的高新技术企业",
                },
                {
                    className: "item hex_2",
                    imgClass: "img_2",
                    name: "合道同",
                    txt: "实现中小型企业“碎片化资源”与“碎片化需求”线上线下的链接互助与价值共享",
                },
                {
                    className: "item hex_3",
                    imgClass: "img_3",
                    name: "医治云康",
                    txt: "致力于智慧城市建设运营和服务",
                },
                {
                    className: "item hex_4",
                    imgClass: "img_4",
                    name: "中技互联",
                    txt: "网站建设、电子商务、移动互联网营销、系统平台开发等",
                },
                {
                    className: "item hex_5",
                    imgClass: "img_5",
                    name: "喜业居",
                    txt: "通过PC端+移动互联网工具，为国内买家、卖家搭建了一个即时、真实、便利的O2O房产咨询、交易直联平台",
                },
            ],
        };
    },
    created() {
        this.routeAdd = this.$route.name;
    },
};
</script>

<style lang="scss" scoped>
.Enter {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: url("../assets/images/enter_background.png") no-repeat;
    .header {
        height: 6.25rem;
    }
    .main {
        flex: 1;
        position: relative;
    }
}
.logo {
    position: absolute;
    top: 10.69rem;
    left: 49.88rem;
}
.item {
    width: 11.88rem;
    height: 6.88rem;
    perspective: 1000;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    transform-style: preserve-3d;
    .flip {
        position: relative;
        transition: 0.6s;
        transform-style: preserve-3d;
    }
    .front,
    .back {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 11.88rem;
        height: 6.88rem;
        backface-visibility: hidden;
        transition: all 0.5s ease-in-out;
    }

    .front {
        background: #fff;
        color: #1a1a1a;
        transform: rotateY(0deg);
        flex-direction: column;
        .name {
            margin: 0.68rem 0;
        }
        .bar {
            width: 1.5rem;
            height: 0.19rem;
            background-color: #333;
        }
        .img_1 {
            width: 4.19rem;
            height: 4.06rem;
            background: url("../assets/images/logo_cy.png") no-repeat;
        }
        .img_2 {
            width: 4.31rem;
            height: 4.13rem;
            background: url("../assets/images/logo_hdt.png") no-repeat;
        }
        .img_3 {
            width: 4.19rem;
            height: 4.19rem;
            background: url("../assets/images/logo_yz.png") no-repeat;
        }
        .img_4 {
            width: 3.75rem;
            height: 4.19rem;
            background: url("../assets/images/logo_zj.png") no-repeat;
        }
        .img_5 {
            width: 6.69rem;
            height: 4.63rem;
            background: url("../assets/images/logo_xyj.png") no-repeat;
        }
    }

    .back {
        padding: 0.94rem;
        box-sizing: border-box;
        background: #e83a42;
        font-size: 14px;
        color: #fff;
        transform: rotateY(-180deg);
    }

    .front:before,
    .back:before,
    .front:after,
    .back:after {
        content: "";
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-left: 5.94rem solid transparent;
        border-right: 5.94rem solid transparent;
    }

    .front:before,
    .back:before {
        top: -3.44rem;
    }

    .front:before {
        border-bottom: 3.44rem solid #fff;
    }

    .back:before {
        border-bottom: 3.44rem solid #e83a42;
    }

    .front:after,
    .back:after {
        bottom: -3.44rem;
    }

    .front:after {
        border-top: 3.44rem solid #fff;
    }

    .back:after {
        border-top: 3.44rem solid #e83a42;
    }
}
.item:hover .front {
    transform: rotateY(-180deg);
}

.item:hover .back {
    transform: rotateY(0deg);
}
.hex_1 {
    position: absolute;
    top: 21.69rem;
    left: 40.35rem;
}
.hex_2 {
    position: absolute;
    top: 21.69rem;
    left: 54.19rem;
}
.hex_3 {
    position: absolute;
    top: 21.69rem;
    left: 68.06rem;
}
.hex_4 {
    position: absolute;
    top: 32.94rem;
    left: 47.56rem;
}
.hex_5 {
    position: absolute;
    top: 32.94rem;
    left: 61.44rem;
}
.text {
    position: absolute;
    top: 46.5rem;
    left: 29.75rem;
    font-size: 18px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
</style>
